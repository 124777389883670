<template>
  <client-only>
    <div class="flex justify-between flex-col min-h-screen">
      <div class="grid w-full h-screen fixed z-50 bg-primary-600">
        <NuxtImg data-not-lazy :src="fileUrl('2bf9513d-778e-44a7-85de-aae7d13155bb')" alt="Vers van de Velde" class="object-scale-down h-16 justify-self-center"/>
        <h1 class="headtext text-2.5xl text-white leading-6 justify-self-center text-center px-5">Vandaag zijn wij gesloten, morgen zijn wij weer geopend.</h1>
      </div>
    </div>
  </client-only>
<!--  <Zondaggesloten/>-->
</template>

<script setup>
// const { $directus } = await useNuxtApp();
const { fileUrl } = await useFiles()
//
// const zondag = await $directus.items('zondag_gesloten').readByQuery({
//   fields: ['tekst_bezorgen', 'popup_trigger'],
// });
// const zondagData = zondag.data

</script>